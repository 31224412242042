export const environment = {
    production: false,
    poolsAddressesURL: 'https://raw.githubusercontent.com/tosdis/TosDisFinance/main/pools.json',
    eth: {
        stakeMasterAddress: '0x4B9C7a8D821d3C0ee79A45798Ab98E4B36D16b43', //kovan address
        UniswapV2Factory: '0x5c69bee701ef814a2b6a3edd4b1652cb9cc5aa6f',
        UniswapV2Router02: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
        WETHAddress: '0xd0a1e359811322d97991e03f863a0c30c2cf029c',
        idoMasterAddress: '0x28197293bd2A7A526069b13E7e91e6d17c57dDe6',//kovan address
        bridgeAddressBSC: "0xf0e42a22440b39630876a655d7486710a5adaa01",
        bridgeAddressFantom: "0xA8E1cB1399832b91AC76f5C71b3D965720910e06",
        DISAddress: "0x29e417721e6B4ed4B3F0Ec982F26F5F153C2E52B" //testnet DIS
    },
    bsc: {
        stakeMasterAddress: '0xBB90f7A35d96a6B7824950900250201c65b86E22',
        UniswapV2Factory: '',
        UniswapV2Router02: '',
        WETHAddress: '',
        idoMasterAddress: '0x5244CAA696DE29f6Dc1Da665ce524881cC685Ff5',
        bridgeAddress: "0xd86eaa0e8d2b4bac9685288cffc662d97e2aa5d7",
        DISAddress: "0xCC66562C54645c76A53212CdF7858E2242fAdB57" //testnet tDIS
    },
    ftm: {
        stakeMasterAddress: '0xBd9Ebd547Eb5f521B27b61AaD5d4E84Ee33a60e4',
        UniswapV2Factory: '',
        UniswapV2Router02: '',
        WETHAddress: '',
        idoMasterAddress: '0xA8E1cB1399832b91AC76f5C71b3D965720910e06',
        bridgeAddress: "0x15eec75cE5dfcd6a4873C53fA8997207db872120",
        DISAddress: "0xdc1c222b5ace804b10b0ac11bea13c113deba826" //testnet tDIS
    },
    remoteServiceBaseUrl: "https://testbridge-api.tosdis.finance",
    defaultChainId: 42,
    supportedChains: [42, 97, 4002],
    logging: true
};