import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "networkName"
})
export class NetworkNamePipe implements PipeTransform {
    transform(value: number): string {
        if (value == 1)
            return "ETH"
        else if (value == 42)
            return "Kovan"
        else if (value == 56)
            return "BSC"
        else if (value == 97)
            return "BSC Testnet"
        else if (value == 256)
            return "Heco Testnet"
        else if (value == 128)
            return "Heco"
        if (value == 250)
            return "Fantom"
        if (value == 4002)
            return "Fantom Testnet";
        return value.toString();
    }
}
