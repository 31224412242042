import { Injectable, EventEmitter } from "@angular/core";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { EventBus } from "./event-bus";
import detectEthereumProvider from "@metamask/detect-provider";
import BigNumber from "bignumber.js";

import stakeMaster from 'assets/abi/StakeMaster.json';
import stakingPool from 'assets/abi/StakingPool.json';
import stakingPoolV2 from 'assets/abi/StakingPoolV2.json';
import IERC20 from 'assets/abi/IERC20.json';
import ERC20Basic from 'assets/abi/ERC20Basic.json';
import UniswapV2Factory from 'assets/abi/UniswapV2Factory.json';
import UniswapV2Pair from 'assets/abi/UniswapV2Pair.json';
import UniswapV2Router from 'assets/abi/UniswapV2Router.json';
import idoMaster from 'assets/abi/IDOMaster.json';
import idoPool from 'assets/abi/IDOPool.json';
import tierSystem from 'assets/abi/TierSystem.json';

import { environment } from "../environments/environment";
import { UserSessionProvider } from "./user-session-provider";
import { NetworkNamePipe } from "./pipes/networkName.pipe";

declare const window: any;

export class ChainError extends Error {
    constructor(message) {
        super(message);
        this.name = "ChainError";
    }
}


@Injectable({
    providedIn: 'root',
})

export class Web3Service {
    public MetamaskName: string = "metamask";
    public WalletconnectName: string = "walletconnect";


    public readonly stakeMasterAbi: any;
    public readonly stakingPoolAbi: any;   
    public readonly stakingPoolAbiV2: any;
    public readonly IERC20Abi: any;
    public readonly ERC20BasicAbi: any;
    public readonly UniswapV2FactoryAbi: any;
    public readonly UniswapV2PairAbi: any;
    public readonly UniswapV2RouterAbi: any;
    public readonly idoMasterAbi: any;
    public readonly idoPoolAbi: any;
    public readonly tierSystemAbi: any;

    public web3: Web3;
    private walletConnectProvider: WalletConnectProvider = new WalletConnectProvider({
        rpc: {
            1: "https://mainnet.infura.io/v3/24327bb89ca04f38991d4b88036b70fa",
            42: "https://kovan.infura.io/v3/24327bb89ca04f38991d4b88036b70fa",
            //BSC mainnet 56 
            56: "https://bsc-dataseed.binance.org/",
            //BSC testnet
            97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
            //Heco testnet
            256: "https://http-testnet.hecochain.com",
            //Fantom
            250: "https://rpcapi.fantom.network/",
            //Fantom Testnet
            4002: "https://rpc.testnet.fantom.network/"
        },
    });

    private ethereumProvider: any;

    public get chainIdNumber(): number {
        return this.userSessionProvider.getChainId();
    };

    public get isETHChain(): boolean {
        //                                  testnet kovan
        if (this.chainIdNumber === 1 || this.chainIdNumber === 42) {
            return true;
        }
        return false;
    }


    public get isBSCChain(): boolean {
        //                                  testnet bsc
        if (this.chainIdNumber === 56 || this.chainIdNumber === 97) {
            return true;
        }
        return false;
    }

    public get isFantomChain(): boolean {
        //                                  testnet fantom
        if (this.chainIdNumber === 250 || this.chainIdNumber === 4002) {
            return true;
        }
        return false;
    }

    constructor(private eventBus: EventBus, private userSessionProvider: UserSessionProvider,) {
        console.log('Web3Service constructor');
        this.stakeMasterAbi = stakeMaster.abi;
        this.stakingPoolAbi = stakingPool.abi;  
        this.stakingPoolAbiV2 = stakingPoolV2.abi;  
        this.IERC20Abi = IERC20.abi;
        this.ERC20BasicAbi = ERC20Basic.abi;
        this.UniswapV2FactoryAbi = UniswapV2Factory.abi;
        this.UniswapV2PairAbi = UniswapV2Pair.abi;
        this.UniswapV2RouterAbi = UniswapV2Router.abi;
        this.idoMasterAbi = idoMaster.abi;
        this.idoPoolAbi = idoPool.abi;
        this.tierSystemAbi = tierSystem.abi;
    }


    async initWeb3() {
        console.log('initWeb3');
        this.ethereumProvider = await detectEthereumProvider({ timeout: 500 });
        if (this.ethereumProvider) {
            this.web3 = new Web3(this.ethereumProvider);

            var metamaskChainId = this.convertChainIdToHex(await this.web3.eth.getChainId());
            //await window.ethereum.request({ method: 'eth_chainId' });
            console.log("matamask chainId: " + metamaskChainId);
            if (parseInt(metamaskChainId, 16) != this.chainIdNumber) {
                this.setWeb3OnCustomRPC();
            }
            //TODO: that = this;
            //Reload when chain was changed in metamask (without connect wallet)
            var that = this;
            if (window.ethereum) {
                window.ethereum.on('chainChanged', function (chainId: string) {
                    console.log(`chainChanged: ${chainId}`);
                    let chainIdNumber = parseInt(chainId, 16);
                    console.log('chainIdNumber: ' + chainIdNumber);
                    if (chainIdNumber != that.chainIdNumber) {
                        if (environment.supportedChains.indexOf(chainIdNumber) >= 0) {
                            that.userSessionProvider.setChainId(chainIdNumber);
                        }
                        else {
                            console.log('finishSession unsupported chain');
                            that.userSessionProvider.finishSession();
                        }
                    }
                    location.reload();
                });
            }
            return;
        }

        else {
            if (!this.web3 || !this.web3.currentProvider) {
                this.setWeb3OnCustomRPC();
            }
        }
    }

    private setWeb3OnCustomRPC() {
        console.log(`set custom RPC for web3. ChainId: ${this.chainIdNumber}`);
        //ETH Mainnet
        if (this.chainIdNumber == 1) {
            this.web3 = new Web3("https://mainnet.infura.io/v3/46e5f1638bb04dd4abb7f75bfd4f8898");
        }
        //Kovan
        else if (this.chainIdNumber == 42) {
            this.web3 = new Web3("https://kovan.infura.io/v3/46e5f1638bb04dd4abb7f75bfd4f8898");
        }
        //BSC
        else if (this.chainIdNumber == 56) {
            this.web3 = new Web3("https://bsc-dataseed.binance.org/");
        }
        //BSC Testnet
        else if (this.chainIdNumber == 97) {
            this.web3 = new Web3("https://data-seed-prebsc-1-s1.binance.org:8545/");
        }
        //Fantom 
        else if (this.chainIdNumber == 250) {
            this.web3 = new Web3("https://rpcapi.fantom.network");
        }
        //Fantom Testnet
        else if (this.chainIdNumber == 4002) {
            this.web3 = new Web3("https://rpc.testnet.fantom.network/");
        }

    }

    public async unlockWalletconnect(reload = false): Promise<string> {
        var data = await this.WalletConnect();
        //this.account = data[0];
        this.userSessionProvider.startSession(data[0], this.WalletconnectName);
        this.eventBus.loginEvent.emit(data[0]);

        if (reload) {
            location.reload();
        }
        return data[0];
    }


    public async unlockMetamask(reload = false) {
        console.log('unlockMetamask');
        if (typeof window.ethereum == 'undefined') {
            //this.translate.get('MetaMask must be installed').subscribe((langResp: string) => {
            throw new ChainError('MetaMask must be installed');
            //});
            return false;
        }

        let chainId = await window.ethereum.request({ method: 'eth_chainId' });
        ////  Get Chain Id
        //TODO: check is this work in wallets
        //var walletChainIdNumber = await this.web3.eth.getChainId();

        let chainIdNumber = parseInt(chainId, 16);
        console.log('chainId: ' + chainId);
        console.log('chainIdNumber: ' + chainIdNumber);
        console.log('web3Service chainId: ' + this.chainIdNumber);

        if (this.chainIdNumber != chainIdNumber) {
            this.userSessionProvider.finishSession();
            throw new ChainError(`Select ${new NetworkNamePipe().transform(this.chainIdNumber)} Network in your wallet.`);
            return false;
        }

        window.ethereum.enable().then((data: any) => {
            console.log("enabled");
            console.log(data);

            if (data.length > 0) {
                //this.account = data[0];
                this.userSessionProvider.startSession(data[0], this.MetamaskName);
                this.eventBus.loginEvent.emit(data[0]);

                //TOOD: that = this;
                var that = this;
                if (window.ethereum) {
                    window.ethereum.on('accountsChanged', function (accounts: any) {
                        console.log('accountsChanged');
                        console.log(accounts);
                        location.reload();
                    })
                }

                //TODO: remove reload, add eventBus
                if (reload) {
                    location.reload();
                }
            }
        }, (reason: any) => {
            console.log("My Permission to connect to Metamask was denied");
            console.log(reason);
        })

        return true;
    }


    async WalletConnect() {
        console.log('WalletConnect');
        //  Create WalletConnect Provider
        this.walletConnectProvider = new WalletConnectProvider({
            rpc: {
                1: "https://mainnet.infura.io/v3/24327bb89ca04f38991d4b88036b70fa",
                42: "https://kovan.infura.io/v3/24327bb89ca04f38991d4b88036b70fa",
                //BSC mainnet 56 
                56: "https://bsc-dataseed.binance.org/",
                //BSC testnet
                97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
                //heco testnet
                256: "https://http-testnet.hecochain.com",
                //Fantom
                250: "https://rpcapi.fantom.network/",
                //Fantom Testnet
                4002: "https://rpc.testnet.fantom.network/"
            },
        });
        console.log(`chainIdNumber: ${this.chainIdNumber}`);
        this.walletConnectProvider.chainId = this.chainIdNumber;

        //  Enable session (triggers QR Code modal)
        var addresses = await this.walletConnectProvider.enable();
        console.log(addresses);

        //  Create Web3
        this.web3 = new Web3(this.walletConnectProvider as any);

        //  Get Chain Id
        var walletChainIdNumber = await this.web3.eth.getChainId();
        console.log('Wallet connect chainId: ' + walletChainIdNumber);
        if (this.chainIdNumber != walletChainIdNumber) {
            throw new ChainError(`Select ${new NetworkNamePipe().transform(this.chainIdNumber)} Network in your wallet.`);
            //this.userSessionProvider.finishSession();
        }

        // Subscribe to accounts change
        this.walletConnectProvider.on("accountsChanged", (accounts: string[]) => {
            console.log("accountsChanged " + accounts);
            this.eventBus.accountsChanged.emit(accounts)
        });

        // Subscribe to chainId change
        this.walletConnectProvider.on("chainChanged", (chainId: number) => {
            console.log("chainChanged" + chainId);

            this.eventBus.chainChanged.emit(this.convertChainIdToHex(chainId));
        });

        // Subscribe to session connection
        this.walletConnectProvider.on("connect", () => {
            console.log("connect");
            this.eventBus.walletConnect.emit("");
        });

        // Subscribe to session disconnection
        this.walletConnectProvider.on("disconnect", (code: number, reason: string) => {
            console.log(code, reason);
            this.eventBus.walletDisconnect.emit(reason);
        });


        //console.log(this.web3);
        return addresses;
    }


    public convertChainIdToHex(value: number): string {
        var hexChainId = '0x' + value.toString(16);
        if (hexChainId === '0x1')
            hexChainId = "0x01";
        return hexChainId;
    }

    async WalletDisconnect() {
        if (this.walletConnectProvider) {
            // Close provider session
            await this.walletConnectProvider.disconnect()
        }
    }


    //#region web3
    async GetTransactionReceipt(tx: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.web3.eth.getTransactionReceipt(tx, (error, resp) => {
                console.log(resp);
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async GetDecimals(contractAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.ERC20BasicAbi, contractAddress);
            contract.methods.decimals().call({}, (error, resp) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async GetTotalSupply(contractAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.ERC20BasicAbi, contractAddress);
            contract.methods.totalSupply().call({}, (error, resp) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }


    async GetAllowance(account: string, tokenForspend: string, forContractAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.ERC20BasicAbi, tokenForspend);
            contract.methods.allowance(account, forContractAddress).call({}, (error, resp) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async GetTokenBalance(account: string, tokenAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.ERC20BasicAbi, tokenAddress);
            contract.methods.balanceOf(account).call({}, (error, resp) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async GetContractSymbol(tokenAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.ERC20BasicAbi, tokenAddress);
            contract.methods.symbol().call({}, (error, resp) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    //async Approve(account: string, tokenForspend: string, forContractAddress: string): Promise<any> {
    //    return new Promise((resolve, reject) => {
    //        // Get contract instance
    //        let tokenContract = new this.web3.eth.Contract(this.IERC20Abi, tokenForspend);

    //        tokenContract.methods.approve(forContractAddress, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
    //            .send({ from: account }, (error, resp) => {
    //                console.log(resp);
    //                resolve(resp);
    //            });
    //    }) as Promise<any>;
    //}


    async ApproveOn(account: string, tokenForspend: string, forContractAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            // Get contract instance
            let tokenContract = new this.web3.eth.Contract(this.IERC20Abi, tokenForspend);

            return tokenContract.methods.approve(forContractAddress, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
                .send({ from: account })
                .on('transactionHash', function (hash) {
                    console.info('transactionHash');
                    console.info(hash);
                })
                .on('receipt', function (receipt) {
                    console.info('receipt');
                    console.info(receipt);
                    resolve(receipt);
                })
                .on('error', function (error, receipt) {
                    console.error(error);
                    console.error(receipt);
                    reject(error);
                    //this.waiting = false;
                });
        }) as Promise<any>;
    }

    //#region Uniswap

    //#endregion Uniswap

    async GetPairAddress(uniswapV2Factory: string, contract1: string, contract2: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.UniswapV2FactoryAbi, uniswapV2Factory);
            contract.methods.getPair(contract1, contract2).call({}, (error, resp) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }


    async getAmountsOut(uniswapV2Router02: string, amountIn: number, path: string[]): Promise<any> {
        return new Promise((resolve, reject) => {
            let stringAmountIn = "0x" + new BigNumber(amountIn).toString(16);
            let contract = new this.web3.eth.Contract(this.UniswapV2RouterAbi, uniswapV2Router02);
            contract.methods.getAmountsOut(stringAmountIn, path).call({}, (error, resp) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }


    async getUniToken0(tokenAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.UniswapV2PairAbi, tokenAddress);
            contract.methods.token0().call({}, (error, resp) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async getUniToken1(tokenAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.UniswapV2PairAbi, tokenAddress);
            contract.methods.token1().call({}, (error, resp) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async getUniReserves(tokenAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.UniswapV2PairAbi, tokenAddress);
            contract.methods.getReserves().call({}, (error, resp) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async getEthBalance(customerAddress): Promise<any> {
        return new Promise((resolve, reject) => {
            this.web3.eth.getBalance(customerAddress, (error, balance) => {
                resolve(balance);
            });
        }) as Promise<any>;
    }

    async getOwner(contractAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            //TODO: don't work
            //let abi = [{
            //    inputs: [],
            //    name: "owner",
            //    outputs: [
            //        {
            //            internalType: "address",
            //            name: "",
            //            type: "address"
            //        }
            //    ],
            //    stateMutability: "view",
            //    type: "function",
            //    constant: true
            //}];
            let contract = new this.web3.eth.Contract(this.stakeMasterAbi, contractAddress);
            contract.methods.owner().call({}, (error, resp) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    //#region  StakingPool

    async GetContractVersion(poolAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.stakingPoolAbiV2, poolAddress);
            contract.methods.version().call({}, (error, resp) => {
                console.log(`getContractVersion ${resp}`);
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async GetStakingPoolHasWhitelisting(poolAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.stakingPoolAbiV2, poolAddress);
            contract.methods.hasWhitelisting().call({}, (error, resp) => {
                console.log(`hasWhitelisting ${resp}`);
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async GetStakingPoolAllowReinvest(poolAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.stakingPoolAbiV2, poolAddress);
            contract.methods.allowReinvest().call({}, (error, resp) => {
                console.log(`allowReinvest ${resp}`);
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async GetPoolTokenAmount(poolAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.stakingPoolAbiV2, poolAddress);
            contract.methods.poolTokenAmount().call({}, (error, resp) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }
    
    //#endregion  Tiers
    //#region  Tiers

    async vipTier(tierAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.tierSystemAbi, tierAddress);
            contract.methods.vipTier().call({}, (error, resp) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async holdersTier(tierAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.tierSystemAbi, tierAddress);
            contract.methods.holdersTier().call({}, (error, resp) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async publicTier(tierAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.tierSystemAbi, tierAddress);
            contract.methods.publicTier().call({}, (error, resp) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    //TODO: check maxETH is string
    async getMasterMaxEthPayment(userAddress: string, maxETH: string, tierAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.tierSystemAbi, tierAddress);
            contract.methods.getMaxEthPayment(userAddress, maxETH).call({}, (error, resp) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async getFullDisBalance(userAddress: string, tierAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.tierSystemAbi, tierAddress);
            contract.methods.getFullDisBalance(userAddress).call({}, (error, resp) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }
    
    //#endregion Tiers

    //#region  idoMaster

    async getIdoCreatorProxy(masterAddress: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.idoMasterAbi, masterAddress);
            contract.methods.creatorProxy().call({}, (error, resp) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    //#endregion idoMaster

    //#region  idoPool

    async getTierSystemAddress(idoAddress: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.idoPoolAbi, idoAddress);
            contract.methods.tierSystem().call({}, (error, resp) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    async HasWhitelisting(idoAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.idoPoolAbi, idoAddress);
            contract.methods.hasWhitelisting().call({}, (error, resp) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async IsEnableTierSystem(idoAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.idoPoolAbi, idoAddress);
            contract.methods.enableTierSystem().call({}, (error, resp) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }




    async IsWhitelisted(idoAddress: string, userAddress): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.idoPoolAbi, idoAddress);
            contract.methods.isWhitelisted(userAddress).call({}, (error, resp) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }


    //#endregion idoPool

    //#endregion web3

}